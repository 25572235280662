/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import {
  Card, Typography, Button, TextField,
  FormControl, RadioGroup, FormControlLabel, Radio,
  Modal, Select, InputLabel,
} from '@material-ui/core';

import Layout from '../../components/Layout';

import styles from './FasterOutreach.module.scss';

const possibleRoles = [
  'Sourcer/Recruiter',
  'Recruiting Coordinator',
  'Hiring Manager',
  'Head of Talent/Head of People ',
  'CxO',
  'Other',
];
const possibleAts = [
  'Bullhorn',
  'Greenhouse',
  'Lever',
  'Other',
  'None',
];

const FasterOutreach = ({
  location,
}) => {
  const [hours, setHours] = useState("10");
  const [open, setOpen] = useState(false);
  const [ats, setAts] = useState();

  return (
    <Layout
      location={location}
      showHeader={false}
      showFooter={false}
      disableContainer
    >
      <div className={styles.container}>
        <div className={styles.imageDiv}>
          <img
            className={styles.logo}
            src="/images/brand/color/logo.svg"
            alt="Resource Horizontal Logo"
          />
          <Card className={styles.CTACard}>
            <Typography variant='h2'>
              More hires, faster.
            </Typography>
            <Typography variant='p'>
              From one-click lead sourcing to doubling their
              response rates, top sourcing and recruiting teams
              are using Resource for better hiring outcomes in less time.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(true)}
            >
              Start Recruiting Smarter
            </Button>
          </Card>
        </div>
        <div className={styles.ROICalc}>
          <Typography variant='h3'>
            How much time do you spend on outreach?
          </Typography>
          <div className={styles.form}>
            <FormControl component="fieldset">
              <RadioGroup
                className={styles.radioGroup}
                aria-label="hours spent on outreach" 
                name="hours"
                value={hours}
                onChange={(e) => setHours(e.target.value)}
              >
                <div>
                  <FormControlLabel value="10" control={<Radio />} label="10 hrs/week" />
                  <FormControlLabel value="20" control={<Radio />} label="20 hrs/week" />
                </div>
                <div>
                  <FormControlLabel value="30" control={<Radio />} label="30 hrs/week" />
                  <FormControlLabel value="40" control={<Radio />} label="40+ hrs/week" />
                </div>
              </RadioGroup>
            </FormControl>
            <Card className={styles.savingsCard}>
              <Typography variant='h4'>
                Save
              </Typography>
              <Typography variant='h4'>
                {hours === "40" ? `${Math.round(hours * .33)}+` : Math.round(hours * .33)} hours*
              </Typography>
              <Typography variant='h4'>
                Per Week
              </Typography>
            </Card>
          </div>
          <Typography
            className={styles.subtitle}
            variant='p'
          >
            *Estimate only, schedule your discovery call to learn how much time your team can save with Resource.
          </Typography>
        </div>
        <Modal
          className={styles.modalCont}
          aria-labelledby="Get Started Form"
          aria-describedby="Fill out this form to get started with resource"
          open={open}
          onClose={() => setOpen(false)}
        >
          <Card
            className={styles.card}
          >
            <Typography variant="h4">
              Get Started
            </Typography>
            <form action="https://go.pardot.com/l/823443/2020-01-14/z72" method="post">
              <div className={styles.row}>
                <TextField
                  required
                  className={styles.input}
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                />
                <TextField
                  required
                  className={styles.input}
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                />
              </div>
              <div className={styles.row}>
                <TextField
                  required
                  className={styles.input}
                  name="cell"
                  label="Cell"
                  type="tel"
                  placeholder="3603452345"
                  variant="outlined"
                />
                <TextField
                  required
                  className={styles.input}
                  name="companyName"
                  label="Company Name"
                  variant="outlined"
                />
              </div>
              <div className={styles.row}>
                <TextField
                  required
                  className={styles.input}
                  type="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                />
              </div>
              <div className={styles.row}>
                <FormControl
                  className={styles.selectCont}
                  variant="outlined"
                  required
                >
                  <InputLabel
                    className={styles.selectLabel}
                    id="role-label"
                  >
                    Title
                  </InputLabel>
                  <Select
                    native
                    name="role"
                    labelId="role-label"
                  >
                    <option value="" />
                    {possibleRoles.map((role) => (
                      <option value={role}>
                        {role}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={styles.row}>
                <FormControl
                  className={styles.selectCont}
                  variant="outlined"
                  required
                >
                  <InputLabel
                    className={styles.selectLabel}
                    id="ats-label"
                  >
                    ATS
                  </InputLabel>
                  <Select
                    native
                    name="ats"
                    labelId="ats-label"
                    value={ats}
                    onChange={(event) => setAts(event.target.value)}
                  >
                    <option value="" />
                    {possibleAts.map((atsName) => (
                      <option value={atsName}>
                        {atsName}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {ats === 'Other' && (
                <div className={styles.row}>
                  <TextField
                    className={styles.input}
                    type="text"
                    name="otherAts"
                    label="ATS Name"
                    variant="outlined"
                  />
                </div>
              )}
              <Button
                type="submit"
                variant="contained"
                color="secondary"
              >
                Submit
              </Button>
            </form>
          </Card>
        </Modal>
        <script type="text/javascript">
          {`
            piAId = '824443';
            piCId = '3671';
            piHostname = 'pi.pardot.com';
            
            (function() {
              function async_load(){
                var s = document.createElement('script'); s.type = 'text/javascript';
                s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
                var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
              }
              if(window.attachEvent) { window.attachEvent('onload', async_load); }
              else { window.addEventListener('load', async_load, false); }
            })();
          `}
        </script>
      </div>
    </Layout>
  );
}

export default FasterOutreach;
